import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";
import Subscribed from "../components/subscribed/Subscribed";

function SubscribedPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps"]}
          location={location}
          meta={{
            name: "robots",
            content: "noindex",
          }}
          title="Inscription confirmée"
        />
        <Page blue>
          <Subscribed />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

SubscribedPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default SubscribedPage;
