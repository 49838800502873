import PropTypes from "prop-types";
import React from "react";

import { blogUrl } from "../../conf/conf";
import Button from "../button/Button";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import i18n from "./Subscribed.i18n.json";
import styles from "./Subscribed.module.scss";

function Subscribed({ idForGTM, strings }) {
  return (
    <div className={styles["container"]}>
      <h1 className={styles["title"]}>{strings["title"]}</h1>
      <h3 className={styles["subtitle"]}>{strings["subscribed"]}</h3>
      <div className={styles["action"]}>
        <Button href={blogUrl} label={strings["lab"]} />
      </div>
    </div>
  );
}

Subscribed.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
  idForGTM: PropTypes.string,
};

Subscribed.defaultProps = {
  idForGTM: undefined,
};

export default addI18nContextConsumer(i18n)(Subscribed);
